<template>
    <div class="shipments-time-line" :class="[...className, { 'shipments-time-line_exception': exception }]">
        <div class="shipments-time-line__circle shipments-time-line__start"></div>
        <div class="shipments-time-line__progress">
            <div v-if="show">
                <div class="shipments-time-line__progress-line"></div>
                <div class="shipments-time-line__progress-circle">
                    <svg-importer v-if="exception" icon-name="icons/exception" />
                </div>
            </div>
        </div>
        <div class="shipments-time-line__circle shipments-time-line__end"></div>
    </div>
</template>

<script>
//load
//canceled
export default {
    props: {
        value: {
            type: String,
        },
        exception: {
            type: String,
        }
    },
    
    computed: {
        show() {
            const status = this.value;

            if (status === 'IN_TRANSIT' || status === 'READY_FOR_COLLECTION') {
                return true;
            }

            return false;
        },

        className() {
            const status = this.value;
            const name = 'shipments-time-line';

            if (status === 'PRE_TRANSPORT' || status === 'CONFIRMED') {
                return [`${name}_load`];
            }

            if (status === 'IN_TRANSIT' || status === 'READY_FOR_COLLECTION') {
                return [`${name}_progress`];
            }

            if (status === 'DELIVERED' || status === 'RETURNED') {
                return [`${name}_delivery`];
            }

            return [`${name}_canceled`];
        }
    }
}
</script>

<style lang="scss">
@import '@/styles/components/_shipment-timeline.scss';
</style>