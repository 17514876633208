import table from "./table";
import AppTh from './AppTh'
import AppTableLoading from './AppTableLoading'
import Vue from "vue";

export default {
    name: "VirtualTable",

    components: {
        AppTh,
        AppTableLoading,
    },

    props: {
        id: {
            type: String
        },
        branding: {
            required: false,
            default: true,
            type: Boolean
        },
        rows: {
            type: Array,
            default: () => []
        },
        columns: {
            type: Array,
            default: () => []
        },
        queryParams: {
            type: Boolean,
            default: () => false
        },
        rowKey: {
            type: String,
            default: () => "id"
        },
        debug: {
            type: Boolean,
            default: () => false
        },
        endpoint: {
            type: String,
            default: () => null
        },
        exportEndpoint: {
            type: String,
            default: () => null
        },
        checkable: {
            type: Boolean,
            default: () => false
        },
        textWrappable: {
            type: Boolean,
            default: () => true
        },
        tools: {
            type: Boolean,
            default: () => true
        },
        toolsRight: {
            type: Boolean,
            default: () => true
        },
        panel: {
            type: Boolean,
            default: () => false
        },
        panelRight: {
            type: Boolean,
            default: () => true
        },
        responseProperty: {
            type: String,
            default: () => "data"
        },
        hasDetail: {
            type: Boolean,
            default: () => false
        },
        paginate: {
            type: Boolean,
            default: () => false
        },
        sortKey: {
            type: String,
            default: () => "updated_at"
        },
        sortOrder: {
            type: Number,
            default: () => -1
        },
        loading: {
            type: Boolean,
            default: () => false
        },
        filterParams: {
            type: Object,
            default: () => {}
        },
        noCache: {
            type: Boolean
        },
        onRowsLoaded: {
            type: Function,
            default: () => {}
        },
        onRowsPreloaded: {
            type: Function,
            default: () => {}
        },
        static: {
            type: Boolean,
            default: false,
            required: false,
        },
        savedRows: {
            type: Array,
            default: () => []
        },
        requiredFields: {
            type: Array,
            required: false,
            default: () => []
        },
        selected: {
            type: Number,
            default: 0,
        },
        customExport: {
            type: Function,
            default: null
        },
        exportable: {
            type: Boolean,
            default: true,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        draggable: {
            type: Boolean,
            default: false,
        },
        keyId: {
            type: String,
            default: 'id',
        },
        itemSize: {
            type: Number,
            default: 43.97,
        },
        savedData: {
            default: null,
        },
        customCheckable: {
            type: Boolean,
            default: false,
        },
        settingsIconsOutline: {
            type: Boolean,
            default: true,
        },
        serializeParams: {
            type: Boolean,
            default: false,
        },
        filters: {
            type: Object,
            default: () => { return {} },
        },
    },

    data: () => ({
        currentDetail: null,
        overflowMargin: 0,
        toggleTableTools: false,
        copy: false,
    }),

    computed: {
        total() {
            return this.table.total
        },
        overflowMarginStyle() {
            return `padding-bottom: ${this.overflowMargin}px; margin-bottom: -${this.overflowMargin}px;`;
        },
    },

    watch: {
        total() {
            this.$emit('total', this.total)
        },
        loading(to, from) {
            this.table.loading = to;
        },
        rows(to, from) {
            this.table.loading = true;
            this.table.isLoading = true;

            this.table.rows = this.rows;

            this.table.loading = false;
            this.table.isLoading = false;
        },
        $route(to, from) {
            if (!to.query['filter[search]']) {
                this.filterTerm = "";
            } else {
                this.filterTerm = to.query['filter[search]'];
            }

            if (to.query && to.query.page) {
                if (!this.table.rows || this.table.rows.length === 0 || this.queryParams) {
                    this.table.fetchRows();
                }

                this.table.syncState(to.query);

                this.$emit('query-updated-again')
            }
        },
        filterParams(to, from) {
            this.table.loading = true;

            this.table.goToPage(1);
            // this.table.filterParams = to;
            this.$set(this.table, 'filterParams', to);
            this.table.commitState();

            this.table.
            loading = false;
        },
        filters:{
            handler(){
                this.table.filters = this.filters;
            },
            deep: true
        },
        'table.rows'() {
            this.$emit('table-rows', this.table.rows);
        },
        'table.pageSize'() {
            this.$emit('updated-page-size', this.table.pageSize);
        },
        'table.page'() {
            this.$emit('updated-page', this.table.page);
        },
        'table.sendResponse'() {
            this.$emit('send-response', true)
        },
        'table.columns': {
            handler(to, from){
                this.$emit('updated-columns', this.table.columns);
            },
            deep: true
        },
        'table.sortKey'() {
            this.$emit('updated-sort-key', this.table.sortKey);
        },
        'table.sortOrder'() {
            this.$emit('updated-sort-order', this.table.sortOrder);
        },
        'table.total'() {
            this.$emit('updated-total', this.table.total);
        }
    },
    created() {
        this.table.serializeParams = this.serializeParams;

        if (this.paginate)  {
            this.table.pageSize = 50;
        }

        this.initTable();
        this.setData();
    },
    methods: {
        tdClassName(table, index) {
            const className = table[index];

            if (!className) { 
                return '' 
            }

            return `${className.width} min-${className.width}`;
        },
        eventClick(event) {
            this.$emit('event-click', event);

            event.stopPropagation()
        },
        setOverflowMargin(value) {
            this.overflowMargin = value;
        },
        getSortKey(col) {
            if (! col.sortable) {
                return null;
            }

            return col.sortable_key ? col.sortable_key : col.key;
        },
        rowClick(row, index) {
            this.$emit('row-click', { data: row, index: index})
        },
        rowDbClick(row, index) {
            this.$emit('row-dbclick', { data: row, index: index})
        },
        initTable() {
            this.table.id = this.id;
            this.table.query = this.query;
            this.table.rows = this.rows;
            this.table.queryParams = this.queryParams;
            this.table.endpoint = this.endpoint;
            this.table.exportEndpoint = this.exportEndpoint;
            this.table.checkable = this.checkable;
            this.table.responseProperty = this.responseProperty;
            this.table.hasDetail = this.hasDetail;
            this.table.sortKey = this.sortKey;
            this.table.sortOrder = parseInt(this.sortOrder);
            this.table.loading = this.loading;
            this.table.filterParams = this.filterParams;
            this.table.noCache = this.noCache;
            this.table.onRowsLoaded = this.onRowsLoaded;
            this.table.onRowsPreloaded = this.onRowsPreloaded;
            this.table.customExport = this.customExport;

            if (this.savedData?.table_settings?.length) {
                this.table.columns = this.savedData.table_settings;
                this.table.pageSize = +this.savedData.count_rows;
                this.table.page = +this.savedData.pagination;

                return;
            }

            if (this.filters) {
                this.table.filters = this.filters;
            }

            this.table.setColumns(this.columns);
        },
        setData() {
            if (this.queryParams) {
                this.table.router = this.$router;
                this.table.route = this.$route;
            }

            this.table.rows = this.rows;

            if (this.queryParams && !this.endpoint) {
                /*
                        If we want to base the table on the queryParams, and a page is set in the url
                    */
                // Query params and page set
                this.table.syncState(this.$route.query);
                this.$emit('query-updated')

                return;
            }

            if (this.queryParams && this.endpoint) {
                /*
                        If we DO want to base the table on the queryParams, but use internal fethcing for rows
                    */
                // query params with endpoint
                this.table.commitState();

                return;
            }

            if (!this.queryParams && this.endpoint) {
                /*
                        If we DON'T want to base the table on the queryParams, but use internal fethcing for rows
                    */
                // No query params but with endpoint
                this.table.fetchRows();

                return;
            }

            // None of the above
            this.table.commitState();
        },
        toggleAllRows() {
            if (!this.table.allRowsChecked) {
                this.table.checkedRows = [];

                this.$emit('check-row', 'uncheckAllRows')
            } else {
                this.table.checkedRows = this.table.visibleRows.map(
                    row => row[this.rowKey]
                );

                this.$emit('check-row', 'checkAllRows')
            }
        },
        checkedRows() {
            return this.table.checkedRows
        },
        getRows() {
            return this.table.rows
        },
        refreshRows() {
            return this.table.commitState();
        },
        toggleDetail(row) {
            if (this.currentDetail == row[this.rowKey]) {
                this.currentDetail = null;
            } else {
                this.currentDetail = row[this.rowKey];
            }
        },
        isSavedRow(row) {
            if (this.savedRows != null) {
                if(this.savedRows.includes(row.consignment_no)) {
                    return true;
                }
            }
        },
        isErrorMarkVisible(row) {
            for (let i = 0; i < this.requiredFields.length; ++i) {
                const fieldName = this.requiredFields[i];
                if (!row[fieldName]) {
                    return true;
                }
            }
            return false;
        },
        tdClasses(col, index) {
            return [
                {
                    'text-right pr-6': col.alignRight,
                    'overflow-visible-important': col.title === 'Sites' || col.key === 'consignments' || col.overflow,
                    'the-last': index > this.table.visibleRows.length - 5,
                    'sticky': col.pin,
                    'p-1': col.pin,
                    'truncate': col.truncate === undefined || col.truncate === true,
                    'align-middle': true
                },
                col.tdClass,
            ];
        },
        pinComputed(value, index) {
            if (!value.pin) {
                return '';
            }

            if (index > 2) {
                return '';
            }

            if (index === 0) {
                return { left: `0px` }
            }

            if (index === 1) {
                return { left: `${+this.table.toggledColumns[0].width.slice(2) / 4}rem` }
            }

            if (index === 2) {
                const first = +`${+this.table.toggledColumns[0].width.slice(2) / 4}`;
                const prev =  +`${+this.table.toggledColumns[1].width.slice(2) / 4}`;

                return { left: `${first + prev}rem` }
            }
        },
        pinStyleTh(value, index) {
            return this.pinComputed(value, index);
        },
        pinStyleTd(index) {
            const value = this.table.toggledColumns[index];

            return this.pinComputed(value, index);
        }
    },
    beforeCreate() {
        this.table = new Vue(table);
    },
    provide() {
        return {
            table: this.table
        };
    },
};