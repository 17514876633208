<template>
    <div class="w-full flex">
        <div class="app-table__tbody flex-1">
            <div class="app-table__tr-wr app-table__tr-wr_loading" v-for="n in getRows" :key="n">
                <div class="app-table__tr app-table__tr_loading">
                    <div v-if="checkable" class="app-table__td app-table__td_checkable w-18 min-w-12 pr-1">
                        <span class="h-4 w-3/4 inline-block textdivansparent linear-background mr-1"></span>
                        <span class="h-4 w-3/4 inline-block textdivansparent linear-background"></span>
                    </div>

                    <div v-if="hasDetail" class="app-table__td w-12 min-w-12 p-0">
                        <span class="h-4 w-3/4 inline-block textdivansparent linear-background"></span>
                    </div>

                    <div v-for="i, index in cols" :key="index" class="app-table__td" :class="[i.width, `min-${i.width}`, {'sticky': i.pin, 'z-10': i.pin, 'pl-1': i.pin}]" :style="pinStyleTh(i, index)">
                        <span class="h-4 w-3/4 inline-block textdivansparent linear-background"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            cols: {
                type: Array,
                default: () => { return [] },
            },
            rows: {
                type: Number,
            },

            checkable: {
                type: Boolean,
            },

            hasDetail: {
                type: Boolean,
            }
        },
        
        computed: {
            getRows() {
                if (this.rows <= 50)  {
                    return this.rows;
                }

                return 50;
            }
        },

        methods: {
            pinComputed(value, index) {
                if (!value.pin) {
                    return '';
                }

                if (index > 2) {
                    return '';
                }

                if (index === 0) {
                    return { left: `0px` }
                }

                if (index === 1) {
                    return { left: `${+this.cols[0].width.slice(2) / 4}rem` }
                }

                if (index === 2) {
                    const first = +`${+this.cols[0].width.slice(2) / 4}`;
                    const prev =  +`${+this.cols[1].width.slice(2) / 4}`;

                    return { left: `${first + prev}rem` }
                }
            },

            pinStyleTh(value, index) {
                return this.pinComputed(value, index);
            },
        }
    };
</script>