const LITERALS = ['string', 'number', 'boolean'];
import moment from 'moment';

export const prepareFiltersForSave = (filters) => {
    if (!filters) {
        return '';
    }

    const query = {};

    Object.keys(filters).forEach(key => {
        const value = filters[key];

        if (!value) {
            return;
        }

        const isEmptyArray = Array.isArray(value) && !value.length;
        const isEmptyDate = typeof value === 'object' && 'startDate' in value && !value.startDate;

        if (isEmptyArray || isEmptyDate) {
            return;
        }

        if (['number', 'string'].includes(typeof value)) {
            query[key] = value;

            return;
        }

        if (typeof value === 'object' && ('startDate' in value || 'endDate' in value)) {
            query[key] = [value.startDate, value.endDate].map(val => val ? moment(val).format('YYYY-MM-DD'): '').filter(value => value).join(',')

            return;
        }

        if (Array.isArray(value)) {

            if (LITERALS.includes(typeof value[0])) {
                query[key] = value.join(',');

                return;
            }

            query[key] = value.map(val => val.value || val.id).join(',');
        }
    });

    return query;
};