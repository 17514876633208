<template>
    <div class="shipments-list-item__status-wrapper" :class="{ active: !isCancelled }">
        <div class="shipments-list-item__status">
            <svg-importer
                :icon-name="`icons/${isCancelled ? 'close' : getTransportModeIcon(mode)}`"
                :class="getTransportModeIcon(mode)"
            />
            <span class="text-subheader">
                {{ status }}
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Status',
    props: {
        mode: String,
        status: String,
    },
    computed: {
        isCancelled() {
            return this.status === 'CANCELLED';
        },
    },
}
</script>
<style lang="scss">
    $default: #F7F7F7;

    .shipments-list-item__status {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;

        &-wrapper {
            padding: 4px 16px;
            min-width: 121px;
            height: 32px;

            border-radius: 24px;
            background: $default;
        
            &.active {
                background: var(--brand-color-secondary);
                svg, svg path {
                    stroke: var(--brand-color);
                }

                svg {
                    &.express {
                        circle {
                            fill: var(--brand-color);
                        }
                    }
                    &.courier {
                        path {
                            fill: var(--brand-color-secondary);
                        }
                    }
                    &.plane {
                        path {
                            fill: var(--brand-color);
                        }
                    }
                }

                .text-subheader {
                    color: var(--brand-color);
                    white-space: nowrap;
                }
            }
        }
        
    }
</style>