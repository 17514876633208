<template>
    <div class="w-full">
        <v-debug v-if="debug" />

        <v-panel v-if="panel" :show-settings="false" :export="customExport"  :toolsRight="panelRight" :branding="branding" :selected="selected" @toggleTableTools="toggleTableTools = $event" :columns="columns">
            <template slot="panel">
                <slot name="panel"></slot>
            </template>

            <template slot="export">
                <slot name="export"></slot>
            </template>

            <template slot="panel_right">
                <slot name="panel_right"></slot>
            </template>
        </v-panel>

        <v-tools v-if="tools" :export="customExport"  :toolsRight="toolsRight" :branding="branding">
            <template slot="tools">
                <slot name="tools"></slot>
            </template>
        </v-tools>


        <div class="shipments-list table-fixed border-radius" v-if="!table.loading && !table.isLoading">
            <div 
                v-for="(item) in tableRows"
                :key="item.uuid"
                class="shipments-list__item"
                @dblclick="rowDbClick(item);"
            >
                <shipments-list-item 
                    :value="item"
                    :value-checkbox="item[rowKey]"
                    class="border-radius--l"
                >
                    <div @click.stop class="flex flex__align-center">
                        <div v-if="isSavedRow(item)" class="saved-row"></div>
                        <div v-if="isErrorMarkVisible(item)" class="saved-row saved-row--error"></div>

                        <label class="text-body color-text-secondary relative flex f-checkbox cursor-pointer">
                            <input
                                type="checkbox"
                                v-model="table.checkedRows"
                                :value="item[rowKey]"
                                @click="$emit('check-row', item)"
                                class="form-checkbox visually-hidden"
                            />

                            <div class="f-checkbox__toggle">
                                <svg-importer icon-name="icons/checkbox-check" />
                            </div>
                        </label>
                    </div>
                </shipments-list-item>
            </div>
        </div>

        <div v-else class="shipments-list-loading">
            <app-spinner class="shipments-list__loading" />
        </div>

        <!-- <div class="block shipments-list table-fixed" :class="{ 'whitespace-no-wrap': textWrappable, 'shipments-list_loading': table.loading || table.isLoading }">
            <recycle-scroller
                class="shipments-table"
                :items="table.loading || table.isLoading ? [] : table.visibleRows"
                :item-size="itemSize"
                :key-field="keyId"
                :class="{scroller: (table.loading || table.isLoading) || table.visibleRows.length}"
            >

                <template v-if="table.loading || table.isLoading" #after>
                    <div class="flex flex__justify-center shipments-list__loading-wrapper">
                        <app-spinner class="shipments-list__loading" />
                    </div>
                </template>

                <template v-if="!table.loading && !table.isLoading" v-slot="{ item, index }">
                    <div class="shipments-list__item">
                        <shipments-list-item :value="item" />
                    </div>
                </template>
            </recycle-scroller>
        </div> -->

        <v-pagination v-if="!paginate" />

        <v-paginate v-else :selected="selected" :toggleTableTools="toggleTableTools" />

        <slot v-if="table.total !== 0" name="bottom-tools"></slot>

        <slot v-if="table.total === 0" name="empty"></slot>
    </div>
</template>

<script>
import ShipmentsListItem from './ListItem';
import tableConfig from '~/components/vtable/config'
import AppSpinner from '~/components/AppSpinner';

import axios from 'axios';

export default {
    mixins: [tableConfig],

    data() {
        return {
            goodTypeDictionary: [],
        }
    },

    computed: {
        tableRows() {
            if (!this.goodTypeDictionary.length) {
                return this.table.visibleRows;
            }

            return this.table.visibleRows.map(row => {
                row.goods_type_name = this.goodTypeDictionary.find(dictionaryEntry => dictionaryEntry.code === row.goods_type)?.name || '-';

                return row;
            });
        },
    },

    methods: {
        rowDbClick(row) {
            window.open(`/transport/shipments/${row.uuid}`, '_blank');
        },
    },

    components: {
        ShipmentsListItem,
        AppSpinner,
    },

    async mounted() {
        const { data: { data } } = await axios.get(this.$apiUrl.goodTypeDictionary.base + '?pageSize=10000');
        this.goodTypeDictionary = data;
    },
}
</script>

<style lang="scss">
@import 'src/styles/pages/shipments-list.scss';
</style>
