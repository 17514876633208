<template>
    <div class="flex items-center">
      <label class="text-body color-text-secondary relative flex f-checkbox cursor-pointer">
        <input
          v-model="modelValueInternal"
          :id="id || name"
          :name="name"
          type="checkbox"
          :disabled="disabled"
          :value="value"
          class="hidden"
        >
        <div class="f-checkbox__toggle mr-3" :class="{'no-branding': !branding}">
          <svg-importer icon-name="icons/checkbox-check" />
        </div>
  
        <div class="color-text-primary f-checkbox__text text-draggable">
          <slot />
        </div>
      </label>
    </div>
  </template>
  
  <script>
  export default {
    name: 'V-Input-Checkbox',

    props: {
      id: { type: [String, Number], default: null },
      name: { type: [String, Number], default: 'checkbox' },
      value: { type: [Boolean, Number,String], default: false },
      modelValue: { type: Array, default: () => []},
      checked: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      branding: { type: Boolean, default: true, required: false },
    },
  
    model: {
      prop: 'modelValue',
      event: 'change',
    },

    computed: {
        modelValueInternal: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('change', value);
            },
        },
    },
  }
  </script>
  
  <style lang="scss">
  @import 'src/styles/components/_checkbox.scss';
  </style>
  