<template>
  <div v-if="!hidden" class="whitespace-no-wrap app-table__th text-subheader color-text-secondary" :class="sortEnabled ? 'cursor-pointer' : 'cursor-not-allowed'" @click="sort">
    <span v-if="sortEnabled" class="mr-1">
      <span>
        <svg-importer v-if="currentSort(1)" :key="`${sortKey}_up`" icon-name="icons/table-sort-up" />
        <svg-importer v-else-if="currentSort(-1)" :key="`${sortKey}_down`" icon-name="icons/table-sort-down" />
        <svg-importer v-else-if="table.sortKey !== sortKey" :key="`${sortKey}_default`" icon-name="icons/table-sort" />
      </span>
    </span>

    <slot />
  </div>
</template>

<script>
import { uuid } from './utils'

export default {
  name: 'VTh',
  props: {
    sortKey: {
      required: false,
      type: [String, Function]
    },
    exportKey: {
      required: false,
      type: [String, Function]
    },
    hidden: {
      required: false,
      type: [Boolean]
    }
  },
  inject: ['table'],
  data () {
    return {
      id: uuid(),
      order: 1
    }
  },
  computed: {
    sortEnabled () {
      return this.sortKey
    }
  },
  mounted () {
    if (this.exportKey) {
      this.table.setExportKey(this.exportKey)
    }
  },
  methods: {
    currentSort (dir) {
      if (this.table.sortKey === this.sortKey && this.table.sortOrder == dir) {
        return true
      }

      return false
    },
    sort () {
      if (!this.sortEnabled) return

      if (this.table.sortKey === this.sortKey) {
        this.order = +this.table.sortOrder === 1 ? -1 : 1
      }

      this.table.setSort({
        sortOrder: this.order,
        sortKey: this.sortKey
      })
    }
  }
}
</script>

<style scoped>
.vt-sort {
  cursor: pointer;
}
</style>
