<template>
  <div class="relative">
    <button :title="buttonTitleText" :disabled="!consignments.length" class="icon outline-default border-color mr-4 cursor-pointer" @click="isShow = !isShow">
        <svg-importer icon-name="icons/print" class="mr-2" />
        <span class="mr-2">Print</span>
    </button>
    <div v-show="isShow" class="absolute shipments-freight-modal py-3 px-3 border-radius border">
        <header class="relative">
            <p v-text="titleText" class="font-bold text-lg text-center border-b pb-3 mb-3" />
            
            <button class="absolute right-0 top-0" @click="close">
                <svg-importer icon-name="icons/close" width="18"  />
            </button>
        </header>
        <div class="shipments-freight-modal__list">
            <template v-for="item in carriersInternal" >
                <div v-if="item" :key="`carriersInternal_${item.name}_${componentCounter}`" class="shipments-freight-modal__item">
                    <p v-text="`${item.name} (${item.consignments.length} consignments selected)`" class="font-bold mb-2" />
                    <div
                        v-for="documentType in Object.values(documentTypes)"
                        :key="`${item.name}_${documentType.code}`"
                        class="flex flex__justify-between flex__align-end mb-1"
                    >
                        <label class="flex flex__align-center">
                            <v-input-checkbox v-model="item.types" :value="documentType.code" @change="componentCounter++" /> {{ documentType.label }}
                        </label>
                        <span v-show="printed && !item.links[documentType.code] && item.types.includes(documentType.code)" class="text-sm italic">
                            No document provided
                        </span>
                        <button
                            v-if="item.links[documentType.code]"
                            class="flex flex__align-center color-blue"
                            @click="download(item.links[documentType.code])"
                        >
                            Download
                            <svg-importer icon-name="icons/arrow-down" class="stroke-blue" />
                        </button>
                    </div>
                </div>
            </template>
        </div>
        <footer class="flex flex__justify-center mt-4">
            <button v-show="!printed" class="btn-primary" :class="{ 'btn-loading': loading }" @click="sendPrint">
                Print
            </button>
            <button v-show="printed" class="btn-primary" @click="close">
                Close
            </button>
        </footer>
    </div>
  </div>
</template>

<script>
import { requestErrors } from '~/utils/errors';
import axios from 'axios';

import VInputCheckbox from '~/components/VInputCheckbox';

import DownloadMixin from '~/pages/transport/download.mixin.js';

const DOCUMENT_TYPE_PACKING_CODE = 8;
const DOCUMENT_TYPE_RECEIPT_CODE = 4;

export default {
    name: 'FreightModalComponent',
    components: {
        VInputCheckbox,
    },
    mixins: [DownloadMixin],
    props: {
        consignments: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        carriers(value) {
            this.updateCarriersInternal(value);
        },
    },
    data() {
        return {
            componentCounter: 0,
            isShow: false,
            printed: false,
            loading: false,
            buttonTitleText: 'Allows to print Packing list and/or Receipt list for a number of selected shipments',
            titleText: 'Print freight documents',
            carriersInternal: [],
            documentTypes: {
                // packing: {
                //     code: DOCUMENT_TYPE_PACKING_CODE,
                //     label: 'Packing list',
                // },
                receipt: {
                    code: DOCUMENT_TYPE_RECEIPT_CODE,
                    label: 'Receipt list',
                },
            },
        };
    },
    computed: {
        carriers() {
            return this.consignments.reduce((result, current) => {
                if (!result[current.carrier_data.carrier_id]) {
                    result[current.carrier_data.carrier_id] = current.carrier_data.carrier_name;
                }

                return result;
            }, {});
        },
    },
    methods: {
        close() {
            this.isShow = false;
            this.printed = false;

            Object.values(this.carriersInternal).forEach(carrierValue => {
                carrierValue.types = [DOCUMENT_TYPE_PACKING_CODE, DOCUMENT_TYPE_RECEIPT_CODE];
                // carrierValue.links[this.documentTypes.packing.code] = null;
                carrierValue.links[this.documentTypes.receipt.code] = null;
            });
        },
        getConsignmentsByCarrierId(carrierId) {
            return this.consignments.reduce((result, consignment) => {
                if (consignment.carrier_data.carrier_id === Number(carrierId)) {
                    result.push(consignment.uuid);
                }

                return result;
            }, []);
        },
        updateCarriersInternal(carriers) {
            Object.keys(carriers).forEach(carrierId => {
                if (this.carriersInternal[carrierId]) {
                    this.carriersInternal[carrierId].consignments = this.getConsignmentsByCarrierId(carrierId);

                    return;
                }

                if (!this.carriersInternal[carrierId]) {
                    this.carriersInternal[carrierId] = {
                        name: carriers[carrierId],
                        consignments: this.getConsignmentsByCarrierId(carrierId),
                        types: [DOCUMENT_TYPE_PACKING_CODE, DOCUMENT_TYPE_RECEIPT_CODE],
                        links: {
                            // [this.documentTypes.packing.code]: null,
                            [this.documentTypes.receipt.code]: null,
                        },
                    };
                }
            });

            for (const carrierInternalId in this.carriersInternal) {
                if (carriers[carrierInternalId]) {
                    continue;
                }

                delete this.carriersInternal[carrierInternalId];
            }

            this.printed = false;
        },
        async sendPrint() {
            this.loading = true;

            try {
                const payload = {
                    items: Object.entries(this.carriersInternal).map(([carrierId, data]) => {
                        return {
                            carrier_id: Number(carrierId),
                            consignments: data.consignments,
                            types: data.types
                        };
                    })
                };

                const { data: { data } } = await axios.post(this.$apiUrl.consignments.freightDocuments, payload);

                data.forEach(responseItem => {
                    this.carriersInternal[`${responseItem.carrier_id}`].links[responseItem.type] = responseItem.document;
                });

                this.printed = true;
            } catch (error) {
                this.$snotify.error(requestErrors(error));
            }

            this.loading = false;
        },
    },
}
</script>

<style lang="scss" scoped>
.shipments-freight-modal {
    left: 0;
    top: 50px;
    width: 320px;
    z-index: 1;

    background-color: #fff;

    &::before {
        content: "";
        position: absolute;
        top: -8px;
        left: 12px;

        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 10px solid white;
    }

    &__list {
        max-height: 360px;
        overflow: auto;

        margin: 0 -.75rem;
        padding: 0 .75rem;
    }

    &__item {
        margin-bottom: .75rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>