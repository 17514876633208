import axios from 'axios';

export default {
    data() {
        return {
            loadingUpload: false,
        };
    },
    methods: {
        downloadAll() {
            this.documents.forEach(async item => {
                await this.download(item);
            });
        },
        async download(value) {
            this.loadingUpload = true;

            try {
                await axios({
                    url: `${this.$apiUrl.consignments.documents}/${value.id}/download`,
                    method: 'GET',
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', value.filename); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            } catch(error) {
                this.$snotify.error(error?.response?.data?.message);
            } finally {
                this.loadingUpload = false;
            }
        },
    },
}