export const replacePriceExportColumn = (columns) => {
    const priceColumnIndex = columns.findIndex(col => col.key === 'price_cat1');
    if (priceColumnIndex === -1) {
        return;
    }
    
    columns.splice(
        priceColumnIndex,
        1,
        { title: 'Price', key: 'price' },
        { title: 'Currency', key: 'currency' },
    );
};